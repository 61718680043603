<template>
  <div>
    <div class="wrapper">
      <Header />
      <MenuLocal />

      <div class="content-wrapper" style="min-height: 653px; background-color:#FAFBFD">
        <div class="container-full">
          <!-- Main content -->

          <Modal v-model="modal7" title="Transferer">
            <div class="row"></div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <select class="custom-select form-control required" v-model="data.classe">
                  <option
                    v-for="(data,
                                                            i) in classes"
                    :key="i"
                    :value="data.id"
                  >{{ data.libelleClasse }}</option>
                </select>
              </div>
              <br />
            </div>

            <br />

            <div slot="footer">
              <Button type="primary" size="large" long @click="Transfer()">Enregistrer</Button>
            </div>
          </Modal>

          <Modal v-model="showDelateModal" width="360">
            <p slot="header" style="color:#f60;text-align:center">
              <span>Suppression</span>
            </p>
            <div style="text-align:center">
              <p>Etes-vous sure de vouloir supprimer ?</p>
            </div>
            <div slot="footer">
              <Button type="error" size="large" long @click="delatePaiement">Confirmer</Button>
            </div>
          </Modal>

          <section class="content">
            <div class="row">
              <div class="col-md-12">
                <Upload
                  multiple
                  type="drag"
                  action="api/admin/uploadstudent"
                  :data="this.data"
                  :on-success="handleSuccess
                                                "
                  :on-error="handleError
        "
                  :format="[

        'xls',
        'xlsx'
    ]"
                  :on-format-error="handleFormatError
    "
                  :headers="{
        'X-Requested-With':
            'XMLHttpRequest'
    }"
                >
                  <div style="padding: 20px 0">
                    <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                    <p
                      class="text-center"
                    >Cliquer ici pour charger un Fichier importation des eleves</p>
                  </div>
                </Upload>
              </div>
            </div>

            <div class="row pt-5">
              <div class="col-12">
                <div class="box">
                  <div
                    class="box-header bg-primary d-flex justify-content-between align-items-center"
                  >
                    <h4 class="box-title m-0">
                      <strong>{{ data.classeId.libelleClasse }}</strong>
                    </h4>

                    <div class="dropdown">
                      <button
                        class="btn-primary btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >Actions</button>
                      <div
                        class="dropdown-menu custom-dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button @click="generateAllPdf" type="button" class="dropdown-item">
                          <strong>Cartes Scolaire</strong>
                        </button>
                        <button @click="generatePdf" type="button" class="dropdown-item">
                          <strong>Liste en PDF</strong>
                        </button>
                        <button @click="generatePdf2" type="button" class="dropdown-item">
                          <strong>Manquements</strong>
                        </button>
                        <button @click="generatePdf3" type="button" class="dropdown-item">
                          <strong>Certificat Scolarite</strong>
                        </button>
                        <button @click="delateAll" type="button" class="dropdown-item">
                          <strong>Tous Supprimer</strong>
                        </button>
                        <button @click="transferAll" type="button" class="dropdown-item">
                          <strong>Transferer tous</strong>
                        </button>
                      </div>
                    </div>

                    <div class="d-flex align-items-center">
                      <h1 type="button" class="mb-0 mr-3">
                        <strong>
                          {{ rows }}
                          <Icon type="ios-school" />
                        </strong>
                      </h1>
                    </div>
                  </div>

                  <div class="box-body">
                    <input
                      type="text"
                      v-model="searchTerm"
                      placeholder="Rechercher un eleve par son  nom , prenon , matricule..."
                      class="form-control mb-3"
                    />
                    <div class="table-responsive">
                      <table class="table product-overview">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th>Noms et prénoms</th>
                            <th>Matricule</th>
                            <th>Dates</th>
                            <th>Lieux</th>
                            <th>Sexe</th>
                            <th>Redoublant</th>
                            <th>Statut</th>
                            <th>Choix</th>

                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, i) in filteredEleves" :key="i">
                            <td>{{ i + 1 }}</td>

                            <td>
                              <img
                                :src="`/Photos/Logos/${data.user.photo}`"
                                alt
                                width="70"
                                height="40"
                              />
                            </td>

                            <td>{{ data.nom }} {{ data.prenom }}</td>
                            <td>{{ data.matricule }}</td>
                            <td>{{ data.dateNaiss | dateFormat }}</td>
                            <td>{{ data.lieuNaiss }}</td>
                            <td>{{ data.sexe }}</td>

                            <td>{{ data.doublant }}</td>
                            <td>
                              <span v-if="data.statut == 2" style="font-size:25px;color:green">
                                <Icon type="md-checkmark-circle" />
                              </span>
                              <span v-if="data.statut == 0" style="font-size:25px;color:red">
                                <Icon type="md-close-circle" />
                              </span>
                              <span
                                v-if="data.statut == 3"
                                style="font-size:25px;color:rgb(22, 20, 21)"
                              >
                                <Icon type="md-warning" />
                              </span>
                            </td>

                            <td>
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  :id="data.id"
                                  :value="data.id"
                                  class="chk-col-danger"
                                  v-model="checkedNames"
                                />

                                <label :for="data.id"></label>
                              </div>
                            </td>

                            <td>
                              <div class="dropdown">
                                <button
                                  class="btn-primary btn-outline btn-sm dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >Actions</button>
                                <div
                                  class="dropdown-menu dropdown-menu-right"
                                  aria-labelledby="dropdownMenuButton"
                                  style="text-align: left;"
                                >
                                  <router-link to="detailsEleve" class="dropdown-item">
                                    <span @click="ParentEleve(data, i)" style="color: black;">
                                      <i class="ti-apps"></i> Voir les détails
                                    </span>
                                  </router-link>

                                  <span
                                    @click="generateCniPdf(data, i)"
                                    class="dropdown-item"
                                    style="color: black;"
                                  >
                                    <i class="ti-printer"></i> Imprimer CNI
                                  </span>

                                  <router-link to="editStudent" class="dropdown-item">
                                    <span @click="ParentEleve(data, i)" style="color: black;">
                                      <i class="ti-pencil"></i> Modifier
                                    </span>
                                  </router-link>

                                  <span
                                    @click="showDelatingModal(data, i)"
                                    class="dropdown-item"
                                    style="color: black;"
                                  >
                                    <i class="ti-trash"></i> Supprimer
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <Chats />
  </div>
</template>

<script>
import Header from "../../headers/Header.vue";
import MenuLocal from "../../navs/MenuLocal.vue";
import Chats from "../../navs/Chats.vue";
import { mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import {
  required,
  minLength,
  alpha,
  email,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";
import { log } from "util";

export default {
  components: { Header, MenuLocal, Chats, VueHtml2pdf },

  computed: {
    filteredEleves() {
      return this.classeListe.filter(eleve => {
        const fullName = `${eleve.nom} ${eleve.prenom} ${eleve.matricule}`.toLowerCase();
        return fullName.includes(this.searchTerm.toLowerCase());
      });
    }
  },
  data() {
    return {
      data: {
        classeId: 12,
        currentPage: 0,
        classe: ""
      },

      searchTerm: "",
      modal7: false,
      rows: 0,
      currentPage: 0,
      classes: "",

      showDelateModal: false,
      delateItem: {},
      checkedNames: [],
      i: -1,
      classeListe: [],
      EtabInfos: [],
      parentEleveInfos: []
    };
  },

  methods: {
    async getAllClasse() {
      const resp = await axios.post("api/locale/getClasseEtablissementTest");
      this.classes = resp.data;
    },
    handlePageChange(value) {
      this.data.currentPage = value - 1;

      console.log(value);

      this.getAll();
    },

    handleSuccess(res, file) {
      this.data.imageEmploiTmp = res;
      this.s("Importation effectuée avec succès");
      this.$router.push("classes");
    },

    handleFormatError(file) {
      this.w("Selectionner un fichier Excel ");
    },

    handleError(res, file) {
      this.w("Une erreur est survenue lors de la procédure.");
    },

    handleBeforeUpload() {
      const check = this.uploadList.length < 1;
      if (!check) {
        this.w("Vous devez inserer un fichier Excel...");
      }
      return check;
    },

    async Transfer() {
      // this.$Spin.show();

      this.data.checkedNames = this.checkedNames;
      const response24 = await this.callApi(
        "post",
        "api/locale/transfertAllStudent",
        this.data
      );

      if (response24.status === 200) {
        this.$Spin.hide();
        this.getAll();
      }
    },

    async delateAll() {
      if (this.checkedNames == "") {
        this.e("Aucune selection faite");
      } else {
        console.log(this.checkedNames);

        this.$Spin.show();

        this.data.checkedNames = this.checkedNames;
        const response24 = await this.callApi(
          "post",
          "api/locale/delateAllStudent",
          this.data
        );

        if (response24.status === 200) {
          this.$Spin.hide();
          this.getAll();
        }
      }
    },

    async transferAll() {
      if (this.checkedNames == "") {
        this.e("Aucune selection faite");
      } else {
        this.modal7 = true;
      }
    },

    async generatePdf3() {
      if (localStorage.classeId) {
        this.data.classeId = JSON.parse(localStorage.getItem("classeId"));
      }

      alert(this.data.classeId);

      window.open(
        "api/locale/getEleveclassePdf3/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse,
        "_blank"
      );

      const responsePdf = await this.callApi(
        "get",
        "api/locale/getEleveclassePdf3/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse
      );
    },

    async getAll() {
      this.$Spin.show();

      const response2 = await this.callApi(
        "post",
        "api/locale/getEleveclasse",
        this.data
      );

      if (response2.status === 200) {
        this.$Spin.hide();
      }

      this.classeListe = response2.data.content;

      this.rows = response2.data.totalPages;
    },
    async delatePaiement() {
      console.log(this.delateItem);
      const response = await axios.post(
        "api/locale/delateEleve",
        this.delateItem
      );
      if (response.status === 200) {
        console.log(this.delateItem);
        this.classeListe.splice(this.i, 1);
        this.showDelateModal = false;
        this.s("Elève supprimée correctement");
      }
      // this.modal2 = false;
    },

    async generateCniPdf(data, i) {
      // // Recuperer tous les infos de cet eleve

      window.open("api/locale/getEleveCniPdf/" + data.id, "_blank");

      const responsePdf = await this.callApi(
        "get",
        "api/locale/getEleveCniPdf/" + data.id
      );
    },

    showDelatingModal(data, i) {
      this.delateItem = data;
      this.i = i;
      this.showDelateModal = true;
    },

    async generatePdf() {
      if (localStorage.classeId) {
        this.data.classeId = JSON.parse(localStorage.getItem("classeId"));
      }

      // console.log(this.data.classeId);

      // Recuperer tous les eleves de cette classe

      window.open(
        "api/locale/getEleveclassePdf/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse,
        "_blank"
      );

      const responsePdf = await this.callApi(
        "get",
        "api/locale/getEleveclassePdf/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse
      );
    },
    async generatePdf2() {
      if (localStorage.classeId) {
        this.data.classeId = JSON.parse(localStorage.getItem("classeId"));
      }

      // console.log(this.data.classeId);

      // Recuperer tous les eleves de cette classe

      window.open(
        "api/locale/getEleveclassePdf2/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse,
        "_blank"
      );

      const responsePdf = await this.callApi(
        "get",
        "api/locale/getEleveclassePdf2/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse
      );
    },

    async generatePdf4() {
      if (localStorage.classeId) {
        this.data.classeId = JSON.parse(localStorage.getItem("classeId"));
      }

      // console.log(this.data.classeId);

      // Recuperer tous les eleves de cette classe

      window.open(
        "api/locale/getEleveclassePdf4/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse,
        "_blank"
      );

      const responsePdf = await this.callApi(
        "get",
        "api/locale/getEleveclassePdf4/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse
      );
    },

    async generateAllPdf() {
      if (localStorage.classeId) {
        this.data.classeId = JSON.parse(localStorage.getItem("classeId"));
        this.data.currentPage = JSON.parse(localStorage.getItem("classeId"));
      }

      window.open(
        "api/locale/index2/" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse,
        "_blank"
      );
      const response2 = await this.callApi(
        "get",
        "api/locale/index2" +
          this.data.classeId.id +
          "*" +
          this.data.classeId.codeEtabClasse
      );

      // Recuperer tous les eleves de cette classe

      //   window.open('api/locale/getAllCniPdf/'+this.data.classeId.id+'*'+this.data.classeId.codeEtabClasse,'_blank')

      //      const responsePdf = await this.callApi(
      //     "get",
      //     "api/locale/getAllCniPdf/"+this.data.classeId.id+'*'+this.data.classeId.codeEtabClasse

      // );
    },

    ParentEleve(data, i) {
      localStorage.setItem("parentEleveInfos", JSON.stringify(data));

      console.log("Je suis la ", data);
    }
  },

  async mounted() {
    // Recuperer les infos de cette classe  dans le storage. classdId  contient toutes les classes et leur eleves respectivement
    // if (!localStorage.users) {
    //   this.$router.push("login");
    // }
    if (localStorage.classeId) {
      this.data.classeId = JSON.parse(localStorage.getItem("classeId"));
      this.data.currentPage = this.data.classeId.id;
    }

    // Recuperer tous les eleves de cette classe

    this.getAll();

    this.getAllClasse();

    // console.log(this.classeListe);
  }
};
</script>

<style>
.content-wrapper {
  background-color: #fafbfd;
}

.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.custom-dropdown-menu {
  min-width: 250px;
  max-width: 400px;

  max-height: 700px;

  max-height: 400px;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
